<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="1000"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules" v-if="visible">
      <a-form-model-item
          label="订单编号"
          prop="orderNo"
          v-bind="formItemLayout">
        <b style="color:blue;margin-left:5px;">{{ form.orderNo }}  </b>
      </a-form-model-item>
      <a-form-model-item
          label="业务名称"
          prop="bizTitle"
          v-bind="formItemLayout">
        <span style="color:#999;margin-left:5px;">{{ form.bizTitle }}  </span>
      </a-form-model-item>

      <a-form-model-item label="订单说明" v-bind="formItemLayout" prop="orderDesc">
        <a-textarea v-model="form.orderDesc" :max-length="255"/>
      </a-form-model-item>
      <a-form-model-item label="下单人名称" v-bind="formItemLayout" prop="userInfo.username">
        <span style="color:#999;margin-left:5px;">{{ form.userInfo && form.userInfo.username }}</span>
      </a-form-model-item>
      <a-form-model-item label="律师名称" v-bind="formItemLayout" prop="lawyerInfo.username">
        <span style="color:#999;margin-left:5px;">{{ form.lawyerInfo && form.lawyerInfo.username }}</span>
      </a-form-model-item>
      <a-form-model-item label="订单状态" v-bind="formItemLayout" prop="status">
        <a-radio-group v-model="form.status">
          <a-radio :value="0">新建</a-radio>
          <a-radio :value="1">已支付</a-radio>
          <a-radio :value="2">履约中</a-radio>
          <a-radio :value="3">已完成</a-radio>
          <a-radio :value="4">已取消</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="支付状态" v-bind="formItemLayout" prop="payStatus">
        <a-radio-group v-model="form.payStatus">
          <a-radio :value="0">待支付</a-radio>
          <a-radio :value="1">已支付</a-radio>
          <a-radio :value="2">已退款</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="下单时间" v-bind="formItemLayout" prop="createTime">
        <span style="color:#999;margin-left:5px;">{{ form.createTime }}</span>
      </a-form-model-item>
      <a-form-model-item label="备注说明" v-bind="formItemLayout" prop="remark">
        <a-textarea v-model="form.remark" :max-length="255"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>

export default {
  name: "modifyModal",
  data() {
    return {
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 15},
      },
      mode: 'new',
      title: "",
      visible: false,
      confirmLoading: false,
      form: {},
      rules: {},
      backReport: undefined
    };
  },
  methods: {
    async showModal(record, mode) {
      this.visible = true;
      this.confirmLoading = false;
      this.mode = mode || 'new'

      if (this.mode === 'update') {
        this.title = "修改订单ID：" + record.id;
        this.form = {
          ...record,
        };

      } else {
        //not support
      }
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;

          this.$postJson("law/order/update", this.form).then((res) => {
            this.visible = false;
            this.confirmLoading = false;
            this.$message.success("修改成功");
            this.$emit("success");
          }).catch(() => {
            this.confirmLoading = false;
          });
        } else {
          this.confirmLoading = false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}
</style>
